import i18n from "../i18n";

export const taskPriorityValueFormatter = value => {
    switch (value) {
        case 1:
            return i18n.t("morning");
        case 2:
            return i18n.t("noon");
        case 3:
            return i18n.t("afternoon");
        default:
            return "";
    }
}
