import moment from "moment";
import _ from "lodash";

/**
 *
 * @param shadow
 * @param aggData
 * @param range
 * @param today
 * @return {{averageDailyFlow: undefined, dailyConsumption: undefined}}
 */
export const getWaterFlowAggregatedDependantValues = ({shadow = {}, aggData = [], range = {}, today = +moment().utc().startOf("day")}) => {
    const result = {
        averageDailyFlow: undefined,
        dailyConsumption: undefined,
        settlementConsumption: undefined
    };
    if (aggData.length) {
        const data = aggData.filter(o => o.AggDt.length);
        if (data.length) {
            const todayData = data.find(o => o.AggTi === today);
            if (todayData) {
                const latestWaterConsumption =
                    _.isFinite(shadow.waterConsumption) ?
                        shadow.waterConsumption
                        :
                        todayData.AggDt[todayData.AggDt.length - 1].MR;
                result.dailyConsumption = latestWaterConsumption - todayData.AggDt[0].MR;
            }
            //zasiedlenie trwa
            if (_.isNil(range.end)) {
                const settlementData = data.filter(o => (o.AggTi >= range.start) && (o.AggTi < today)).sort((o1,o2)=>o1.AggTi - o2.AggTi);
                if (settlementData.length) {
                    const dayDiff =  Math.abs(moment.utc(settlementData[0].AggTi).diff(moment.utc(_.isFinite(shadow.waterConsumption) ? undefined : settlementData[settlementData.length - 1].AggTi).startOf("day"), "days"));
                    const consumptionDiff = (_.isFinite(shadow.waterConsumption) ?
                        shadow.waterConsumption
                        :
                        settlementData[settlementData.length - 1].AggDt[settlementData[settlementData.length - 1].AggDt.length - 1].MR) - settlementData[0].AggDt[settlementData[0].AggDt.length - 1].MR
                    result.averageDailyFlow = consumptionDiff / (dayDiff || 1);
                    result.settlementConsumption = todayData ? consumptionDiff + result.dailyConsumption : consumptionDiff;
                }
            }

        }
    }
    return result;
};
